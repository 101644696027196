section.Home {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 40px;
    box-sizing: border-box;
}

section.Home > svg {
    height: 80px;
    margin-bottom: 20px;
}

section.Home > p {
    max-width: 600px;
    font-size: 12px;
    margin-bottom: 20px;
}

section.Home > a {
    font-size: 14px;
    padding: 10px 20px;
    box-sizing: border-box;
    background-color: red;
    color: #fff;
    border-radius: 50px;
}